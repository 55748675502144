
<div class="module-live-centers">
  <ArticleCarousel
      :id="id"
      :articles="formattedLiveCenterFeeds"
      :is-auto-slider="true"
      :hide-bottom-part="true">
    <template
        v-for="article in formattedLiveCenterFeeds"
        :key="article.order"
        v-slot:[`textContainerHead-${article.order}`]>
      <div class="flex text-container-header topic">
        <div class="icon">
         <svg width="6" height="6" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5" r="4.5" fill="currentColor" stroke="currentColor"/>
          </svg>
        </div>
        <div class="channel-title">
          <a v-if="!!article.channelUrl" :href="article.channelUrl">
            {{ article.channelTitle }}
          </a>
          <span v-else>{{ article.channelTitle }}</span>
        </div>
        <div class="timestamp">{{ article.createdTime }}</div>
      </div>
    </template>
  </ArticleCarousel>
</div>
